<template lang='pug'>
b-modal.loan-financials-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title", { accountType })'
  size    = 'xl'
  lazy
  @shown  = 'resetData'
)
  button.btn.btn-primary.mb-3.fa-pull-right(
    v-if           = 'provision'
    :disabled      = '!loanTransactions.length'
    @click.prevent = 'showProvisionRow = true'
  ) {{ $t('writeOff')}}
  button.btn.btn-primary.mb-3.fa-pull-right(
    v-if           = 'correction'
    @click.prevent = 'showCorrectionRow = true'
  ) {{ $t('addCorrection')}}
  fi-table(
    :fields     = 'isDebt ? fieldsDebt : fields'
    :items      = 'loanTransactions'
    loader      = 'loan:transactions:fetch'
    :emptyLabel = '$t("notFound")'
    responsive
    @sort       = 'setSort'
 )
    template(#custom-row)
      tr.animated.fadeIn(v-if = 'showProvisionRow')
        td
        td {{ $t('common:formatDate', { value: systemData.nextDayChange }) }}
        td {{ $t('credit') }}
        td(v-if = 'vat')
        td
          fi-form-field.mb-0(:state = '!$v.provisionData.amount.$error')
            fi-money-input.form-control.form-control-sm.mr-2(
              v-model        = 'provisionData.amount'
              :class         = '{ "is-invalid": $v.provisionData.amount.$error }'
              :decimal-limit = 6
              autofocus
            )
        td
          fi-form-field.mb-0(:state = '!$v.provisionData.comment.$error')
            input.form-control.form-control-sm.mr-2(
              v-model = 'provisionData.comment'
              :class  = '{ "is-invalid": $v.provisionData.comment.$error }'
            )
        td {{ currentAccount.human }}
        td {{ classifierByName('accountTypes', 'SYSTEM').human }}
          .btn-toolbar.fa-pull-right
            .btn-group-sm.btn-group
              button.btn.btn-primary.btn-sm(
                :disabled      = 'provisioning'
                @click.prevent = 'saveProvision'
              ) {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse(v-if = 'provisioning')
              button.btn.btn-secondary.btn-sm(@click.prevent = 'resetData') {{ $t('common:cancel') }}
      tr.animated.fadeIn(v-if = 'showCorrectionRow')
        td
        td {{ $t('common:formatDate', { value: systemData.nextDayChange }) }}
        td
          fi-switch(
            v-model   = 'correctionData.isDebit'
            :on-text  = '$t("debit")'
            :off-text = '$t("credit")'
          )
        td.money(v-if = 'vat') {{ amountWithVat | money }}
        td
          fi-form-field.mb-0(:state = '!$v.correctionData.amount.$error')
            fi-money-input.form-control.form-control-sm.mr-2(
              v-model        = 'correctionData.amount'
              :class         = '{ "is-invalid": $v.correctionData.amount.$error }'
              :decimal-limit = 6
              autofocus
            )
        td
          fi-form-field.mb-0(:state = '!$v.correctionData.comment.$error')
            input.form-control.form-control-sm.mr-2(
              v-model = 'correctionData.comment'
              :class  = '{ "is-invalid": $v.correctionData.comment.$error }'
            )
        td(colspan = 3)
          .btn-toolbar.fa-pull-right
            .btn-group-sm.btn-group
              button.btn.btn-primary.btn-sm(@click.prevent = 'saveCorrection') {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse(v-if = 'correcting')
              button.btn.btn-secondary.btn-sm(@click.prevent = 'resetData') {{ $t('common:cancel') }}

    template(v-if = '!isDebt' v-slot = '{ items }')
      loan-financials-transaction-list-item(
        v-for        = 'transaction in items'
        :key         = 'transaction.id'
        :transaction = 'transaction'
        :vat         = 'vat'
      )
    template(v-else v-slot = '{ items }')
      loan-financials-transaction-debts-list-item(
        v-for        = 'transaction in items'
        :key         = 'transaction.id'
        :transaction = 'transaction'
        :vat         = 'vat'
      )
  fi-pagination(
    v-if    = 'loanTransactionsPages > 1'
    :pages  = 'loanTransactionsPages'
    v-model = 'pagination.page'
  )
  template(#modal-footer)
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t("common:close") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSwitch from '@/components/FiSwitch'
import FiTable from '@/components/FiTable'
import LoanFinancialsTransactionListItem from '@/views/loans/LoanFinancials/LoanFinancialsTransactionListItem'
import LoanFinancialsTransactionDebtsListItem from '@/views/loans/LoanFinancials/LoanFinancialsTransactionDebtsListItem'
import FiPagination from '@/components/FiPagination'
import FiModal from '@/components/FiModal'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import { validation, validators } from '@/validation'

export default {
  name: 'loan-financials-modal',

  components: {
    FiSwitch,
    FiMoneyInput,
    FiFormField,
    FiPagination,
    LoanFinancialsTransactionListItem,
    LoanFinancialsTransactionDebtsListItem,
    FiTable,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      default: false
    },
    accountType: {
      type: [String, Array],
      default: ''
    },
    totalDebt: {
      type: [Number, String],
      default: ''
    },
    vat: {
      type: Boolean,
      default: false
    },
    provision: {
      type: Boolean,
      default: false
    },
    correction: {
      type: Boolean,
      default: false
    },
    isDebt: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      amountWithVat: '',
      provisionData: {
        amount: this.totalDebt,
        comment: null,
        accountType: null
      },
      correctionData: {
        amount: null,
        comment: null,
        accountType: null,
        isDebit: false
      },
      showProvisionRow: false,
      showCorrectionRow: false,
      pagination: {
        sort: 'id,desc',
        size: 25,
        page: 0
      }
    }
  },

  computed: {
    ...mapState('loans', ['loanTransactions', 'loanTransactionsPages', 'systemData', 'loanProduct']),
    ...mapGetters('classifiers', ['classifierByName']),
    provisioning () {
      return this.$vueLoading.isLoading(`contract:${this.$route.params.id}:provision:save`)
    },
    correcting () {
      return this.$vueLoading.isLoading(`contract:${this.$route.params.id}:correction:save`)
    },
    currentAccount () {
      return this.classifierByName('accountTypes', [
        'LEDGER_PRINCIPAL_RECEIVABLE',
        'LEDGER_INTEREST_RECEIVABLE',
        'LEDGER_PENALTY_RECEIVABLE',
        'LEDGER_FEE_RECEIVABLE',
        'LEDGER_VAT_RECEIVABLE',
        'LEDGER_VAT_TAX_RECEIVABLE'
      ].find(type => Array.isArray(this.accountType) && this.accountType.includes(type)) || this.accountType) ?? {}
    },
    showCorrection () {
      return !Array.isArray(this.accountType)
    },
    showProvision () {
      return Array.isArray(this.accountType)
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    fieldsDebt () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'time',
          label: this.$t('date'),
          sort: 'time'
        },
        {
          key: 'dc',
          label: this.$t('dc')
        },
        ...this.vat ? [{
          key: 'vat',
          label: this.$t('vat'),
          class: 'money'
        }] : [],
        {
          key: 'amount',
          // label: this.$t('amount'),
          label: 'Fee amount',
          class: 'money'
        },
        {
          key: 'feeName',
          label: 'Fee name'
        },
        // {
        //   key: 'feeAmount',
        //   label: 'Fee amount'
        // },
        {
          key: 'comment',
          label: this.$t('comment')
        },
        {
          key: 'accountFrom',
          label: this.$t('accountFrom')
        },
        {
          key: 'accountTo',
          label: this.$t('accountTo')
        }
      ]
    },
    fields () {
      return [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'time',
          label: this.$t('date'),
          sort: 'time'
        },
        {
          key: 'dc',
          label: this.$t('dc')
        },
        ...this.vat ? [{
          key: 'vat',
          label: this.$t('vat'),
          class: 'money'
        }] : [],
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'comment',
          label: this.$t('comment')
        },
        {
          key: 'accountFrom',
          label: this.$t('accountFrom')
        },
        {
          key: 'accountTo',
          label: this.$t('accountTo')
        }
      ]
    }
  },

  watch: {
    value (value) {
      if (!value) return
      this.search()
    },
    'pagination.page': 'search',
    'correctionData.amount' (value) {
      if (value && this.loanProduct.principalWithVat) {
        this.onAmountChange(value)
      }
    }
  },

  validations () {
    return {
      provisionData: {
        amount: {
          required: validators.requiredIf(function () {
            return this.showProvision
          })
        },
        comment: {
          required: validators.requiredIf(function () {
            return this.showProvision
          })
        }
      },
      correctionData: {
        amount: {
          required: validators.requiredIf(function () {
            return this.showCorrection
          })
        },
        comment: {
          required: validators.requiredIf(function () {
            return this.showCorrection
          })
        }
      }
    }
  },

  methods: {
    ...mapActions('loans', ['loadLoanTransactions', 'saveLoanProvision', 'saveLoanCorrection', 'loadLoan']),
    ...mapActions('taxation', ['convertAmount']),
    onAmountChange: debounce(
      async function (amount) {
        this.amountWithVat = (await this.convertAmount({
          amountData: {
            amount,
            amountWithVat: false
          }
        })) - amount
      }, 500
    ),
    resetData () {
      this.$v.$reset()
      Object.assign(this.$data, this.$options.data.apply(this))
      this.provisionData.accountType = this.correctionData.accountType = this.currentAccount.code
    },
    search (params) {
      this.loadLoanTransactions({ filters: { accountTypes: this.accountType, ...this.pagination } })
    },
    async saveProvision () {
      if (this.validate()) {
        await this.saveLoanProvision({ loanId: this.$route.params.id, provisionData: this.provisionData })
        this.resetData()
      } else {
        console.log(this.$v.$anyError)
        console.log('saveProvision validation error')
      }
    },
    async saveCorrection () {
      console.log('accountType = ' + this.correctionData.accountType)
      if (this.validate()) {
        await this.saveLoanCorrection({ loanId: this.$route.params.id, correctionData: this.correctionData })
        await this.loadLoan({ id: this.$route.params.id })
        this.resetData()
      } else {
        console.log(this.$v.$anyError)
        console.log('saveCorrection validation error')
      }
    },
    setSort (sort) {
      this.searchParams.sort = sort
    }
  }
}
</script>


<i18n>
en:
  title:         "{{ accountType }} transactions"
  writeOff:      "Write off"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  vat:           "VAT"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
et:
  title:         "{{ accountType }} transactions"
  writeOff:      "Write off"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  vat:           "VAT"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
ru:
  title:         "{{ accountType }} transactions"
  writeOff:      "Add provision"
  addCorrection: "Add correction"
  debit:         "Debit"
  credit:        "Credit"
  date:          "Date"
  dc:            "Debit/Credit"
  amount:        "Amount"
  vat:           "VAT"
  comment:       "Comments"
  accountFrom:   "Account from"
  accountTo:     "Account to"
  notFound:      "Transactions not found"
</i18n>
